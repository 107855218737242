var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-center custom-slider-wrapper pb-2",
      class: {
        "scale-colors": _vm.scaleColors,
        "tick-italic": _vm.hasCustomLabels,
        "pt-3": _vm.showThumbLabel,
        "no-thumb": !_vm.showThumbLabel
      }
    },
    [
      _c(
        "v-improved-slider",
        {
          attrs: {
            min: _vm.min,
            max: _vm.max,
            value: _vm.selection,
            color: _vm.color,
            "thumb-color":
              _vm.dirty || _vm.selection !== null
                ? _vm.color + "--text"
                : "StarlingLightGrey--text",
            "thumb-label": _vm.showThumbLabel ? "always" : false,
            "always-dirty": _vm.alwaysDirty,
            "hide-details": true,
            ticks: "always",
            "tick-size": "1",
            "tick-labels": _vm.formattedLabels,
            disabled: _vm.disabled,
            readonly: _vm.readonly
          },
          on: {
            start: function($event) {
              _vm.dirty = true
            },
            change: _vm.onChange,
            end: _vm.onChange
          }
        },
        [
          _c("div", { attrs: { slot: "thumb" }, slot: "thumb" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "100%",
                  height: "100%",
                  viewBox: "0 0 45 33",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("rect", {
                  attrs: {
                    width: "44.85",
                    height: "33",
                    rx: "16.5",
                    fill: "currentColor"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M30.5388 24.26L36.7871 18.2833C37.525 17.5775 37.525 16.4225 36.7871 15.7167L30.5388 9.74001",
                    stroke: "white",
                    "stroke-width": "6",
                    "stroke-miterlimit": "10",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M14.4612 9.73999L8.21291 15.7167C7.47499 16.4225 7.47499 17.5775 8.21291 18.2833L14.4612 24.26",
                    stroke: "white",
                    "stroke-width": "6",
                    "stroke-miterlimit": "10",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                })
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }