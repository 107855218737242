<template>
  <div class="text-center custom-slider-wrapper pb-2"
       :class="{ 'scale-colors': scaleColors, 'tick-italic': hasCustomLabels, 'pt-3': showThumbLabel, 'no-thumb': !showThumbLabel }">
    <v-improved-slider :min="min"
                       :max="max"
                       :value="selection"
                       :color="color"
                       :thumb-color="(dirty || selection !== null) ? `${color}--text` : 'StarlingLightGrey--text'"
                       :thumb-label="showThumbLabel ? 'always' : false"
                       :always-dirty="alwaysDirty"
                       :hide-details="true"
                       ticks="always"
                       tick-size="1"
                       :tick-labels="formattedLabels"
                       :disabled="disabled"
                       :readonly="readonly"
                       @start="dirty=true"
                       @change="onChange"
                       @end="onChange">

      <div slot="thumb">
        <svg width="100%" height="100%" viewBox="0 0 45 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="44.85" height="33" rx="16.5" fill="currentColor"/>
          <path d="M30.5388 24.26L36.7871 18.2833C37.525 17.5775 37.525 16.4225 36.7871 15.7167L30.5388 9.74001"
                stroke="white" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14.4612 9.73999L8.21291 15.7167C7.47499 16.4225 7.47499 17.5775 8.21291 18.2833L14.4612 24.26"
                stroke="white" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

    </v-improved-slider>
  </div>

</template>

<script>
import VImprovedSlider from '@/views/components/v-improved-slider.vue';

export default {
  name: 'slider',
  components: { VImprovedSlider },
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    value: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    scaleColors: {
      type: Boolean,
      required: false,
      default: false,
    },
    tickLabels: {
      type: [ Array, Boolean ],
      required: false,
      default: true,
    },
    showThumbLabel: {
      type: Boolean,
      default: true,
    },
    alwaysDirty: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      dirty: false,
      selection: this.value,
    };
  },
  computed: {
    hasCustomLabels() {
      return this.tickLabels && this.tickLabels.length;
    },
    formattedLabels() {
      if (this.hasCustomLabels) return this.tickLabels;
      if (this.tickLabels === false) return [];
      const labels = [];
      labels[0] = this.min.toString();
      for (let i = 1; i < (this.max - this.min); i++) {
        // make all other ticks as empty string, because in UI we want to show first and last
        labels[i] = '';
      }
      labels[labels.length] = this.max.toString();
      return labels;
    },
  },
  watch: {
    value(newValue) {
      this.selection = newValue;
    },
  },
  methods: {
    onChange(newValue) {
      if (newValue === null) newValue = this.min;
      if (newValue !== this.selection) {
        this.$log.debug('Slider value changed', this.selection, newValue);
        this.selection = newValue;
        this.$emit('change', newValue);
      }
    },
  },
};
</script>

<style lang="scss">
.custom-slider-wrapper {
  width: 98%;
  color: var(--StarlingLighterGrey);

  input {
    z-index: 2;
  }

  &.no-thumb .v-input {
    margin-top: 0;
  }

  .v-messages {
    display: none;
  }

  .v-slider__track__container,
  .v-slider__track-fill,
  .v-slider__track {
    height: 12px;
  }

  .v-input--is-disabled .v-slider__track-fill {
    background: #bdbdbd !important;
  }

  .v-slider--is-active .v-slider__thumb-container--is-active.v-slider__thumb-container--show-label .v-slider__thumb {
    -webkit-transform: translateY(-50%) scale(0.8) !important;
    transform: translateY(-50%) scale(0.8) !important;
    cursor: grabbing;
  }

  .v-input--is-focused .v-slider__track__container:after,
  .v-input--slider:not(.v-input--is-dirty) .v-slider__thumb {
    border: none;
  }

  &.tick-italic .v-slider__ticks-container .v-slider__ticks > span {
    font-style: italic;
  }

  .v-slider__thumb-container {
    z-index: 2;

    .v-slider__thumb {
      cursor: grab;
      left: -35px;
      width: 70px;
      height: 52px;
    }

    .v-slider__thumb-label {
      top: -40px;
      border: none !important;
      background-color: transparent !important;
    }
  }

  .v-slider__track__container {
    z-index: 1;
    width: calc(100% + 11px);
    left: -5px;
    border-radius: 50px;

    .v-slider__track {
      background-color: var(--StarlingLighterGrey);
    }
  }

  .v-slider__ticks-container {
    top: 22px;
    height: 18px;
    z-index: 0;

    .v-slider__ticks {
      height: 21px;
      border-color: #c4c4c4;
      border-radius: 5px;
      color: var(--StarlingDarkGrey);
      margin-left: -1px;
    }

    .v-slider__ticks:first-child, .v-slider__ticks:last-child, .v-slider__ticks.v-slider__ticks--large {
      height: 25px;
      border-color: #c4c4c4;
    }

    .v-slider__ticks > span {
      top: 22px;
    }

    .v-slider__ticks:first-child > span {
      left: -5px;
    }

    .v-slider__ticks:last-child > span {
      left: 10px;
    }
  }

  &.scale-colors {
    .v-slider__ticks-container {
      background-image: linear-gradient(90deg, rgba(4, 231, 203, 0.5) 33.33%, rgba(255, 220, 117, 0.5) 33.33%, rgba(255, 220, 117, 0.5) 66.66%, rgba(255, 119, 126, 0.5) 66.66%);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}
</style>
