<script>
// Extensions
import VSlider from 'vuetify/lib/components/VSlider';
import { createRange } from 'vuetify/lib/util/helpers';

const _extends = Object.assign || function (target) {
  for (let i = 1; i < arguments.length; i++) {
    // eslint-disable-next-line
    const source = arguments[i];
    for (const key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }
  return target;
};

/* @vue/component */
export default VSlider.extend({
  name: 'v-improved-slider',
  computed: {
    showTicks () {
      return this.tickLabels.length > 0 ||
        (this.stepNumeric && !!this.ticks);
    },
  },
  methods: {
    genSteps: function genSteps() {
      const _this = this;

      if (!this.step || !this.showTicks) return null;
      const ticks = createRange(this.numTicks + 1).map((i) => {
        const children = [];
        if (_this.tickLabels[i]) {
          children.push(_this.$createElement('span', _this.tickLabels[i]));
        }
        return _this.$createElement('span', {
          key: i,
          staticClass: 'v-slider__ticks',
          class: {
            'v-slider__ticks--always-show': _this.ticks === 'always' || _this.tickLabels.length > 0,
            'v-slider__ticks--large': i === (_this.numTicks / 2),
          },
          style: _extends({}, _this.tickStyles, {
            left: i * (100 / _this.numTicks) + '%',
          }),
        }, children);
      });
      return this.$createElement('div', {
        staticClass: 'v-slider__ticks-container',
      }, ticks);
    },
    genThumb: function genThumb() {
      const children = [];
      if (this.$scopedSlots['thumb']) {
        children.push(this.$scopedSlots['thumb']());
      } else {
        children.push(this.$createElement('div', {
          style: '    left: -27px;\n' +
              '      border: 14px solid;\n' +
              '      border-radius:50%;\n' +
              '      background: white !important;\n' +
              '      width: 55px;\n' +
              '      height: 55px;',
        }));
      }
      return this.$createElement('div', this.setBackgroundColor(this.computedThumbColor, {
        staticClass: 'v-slider__thumb',
      }), children);
    },
  },
});
</script>
